$backround: #d8f0f7;
$white: #ffffff;
$nav: #2186a5;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Poppins;
}

body {
    background: $backround;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

nav {
    width: 100vw;
    height: 5rem;
    background: $nav;
    box-shadow: 0px 6px 10px -1px rgba(143,143,143,0.4);
    display: flex;
    align-items: center;
    padding-left: 4rem;

    h1 {
        color: $white;
        font-size: 2rem;
    }
}

main {
    width: 90%;
    background: $white;
    margin: 0 auto;
    padding: 1rem 2rem;
}

.about {

    display: flex;
    flex-direction: column;
    width: 100%;
    article {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        width: 90%;
        margin: 0 auto;
        position: relative;
        margin-bottom: 4rem;

        &::before{
            content: '';
            width: 4px;
            background: $nav;
            position: absolute;
            top: 0;
            left: -20px;
            bottom: 0;
        }

        img {
            flex: 1;
            width: 16rem;
            border-radius: 15px;
        }
        .text-box {
            flex: 1;
            margin: auto 0;
        }
        p {
            flex: 1;
        }
    }
}

.text-box {
    p {
        margin-bottom: 2rem;
    }
}

ul {
    list-style: none;
    padding-left: 2rem;
}

h2 {
    margin-bottom: 2rem;
}

.testimonial {
    display: flex;
    flex-direction: row;
    border-radius: 15px;
    box-shadow: -22px 20px 22px -21px rgba(143,143,143,0.4);
    margin-bottom: 2rem;
    padding: 1rem;
    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: -22px 20px 22px -21px darken(rgba(143,143,143,0.4), 20%);
        transform: scale(1.01);
    }

    h3 {
        margin-bottom: 1rem;
    }

    img {
        height: 10rem;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        border: 4px solid $nav;
        margin: auto 0;
    }

    video {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        border: 5px solid $nav;
    }

    .content {
        padding-left: 2rem;
        max-width: 80%;
    }
}



.buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    button {
        width: 4rem;
        height: 2rem;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:disabled {
            cursor: default !important;
        }
    }
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        color: grey;
        font-size: 0.8rem;
        padding-bottom: 1rem;
    }
}


@media screen and (max-width: 850px) {
    .about {
        article {
            img {
                height: 15rem;
                aspect-ratio: auto;
            }
            p {
                font-size: 0.8rem;
            }
        }
    }

    .testimonial {
        h3 {
            font-size: 1rem;
        }
        P {
            font-size: 0.8rem;
        }
        img {
            height: 8rem;
        }
        video {
            height: 8rem;
            width: 8rem;
        }
    }
}

@media screen and (max-width: 720px) {
    .about {
        article {
            flex-direction: column;
            gap: 0;

            img {
                width: 100%;
                order: 100;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    
    .testimonial {
        h3 {
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
        }
        P {
            font-size: 0.6rem;
        }
        img {
            height: 6rem;
        }
        video {
            height: 6rem;
            width: 6rem;
        }
    }
    
    ul {
        padding-left: 0;
    }

    nav {
        padding-left: 0;
        h1 {
            margin: 0 auto;
            font-size: 1.5rem;
        }
    }
}

@media screen and (max-width: 450px) {
    .testimonial {
        h3 {
            font-size: 0.5rem;
        }
        p {
            font-size: 0.5rem;
        }
    }
    nav {
        h1 {
            font-size: 1.2rem;
        }
    }

    section {
        h2 {
            font-size: 1rem;
            margin-bottom: 1rem;
        }
    }
}