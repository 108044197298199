* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Poppins;
}

body {
  background: #d8f0f7;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

nav {
  width: 100vw;
  height: 5rem;
  background: #2186a5;
  -webkit-box-shadow: 0px 6px 10px -1px rgba(143, 143, 143, 0.4);
          box-shadow: 0px 6px 10px -1px rgba(143, 143, 143, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 4rem;
}

nav h1 {
  color: #ffffff;
  font-size: 2rem;
}

main {
  width: 90%;
  background: #ffffff;
  margin: 0 auto;
  padding: 1rem 2rem;
}

.about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.about article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 2rem;
  width: 90%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 4rem;
}

.about article::before {
  content: '';
  width: 4px;
  background: #2186a5;
  position: absolute;
  top: 0;
  left: -20px;
  bottom: 0;
}

.about article img {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 16rem;
  border-radius: 15px;
}

.about article .text-box {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: auto 0;
}

.about article p {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.text-box p {
  margin-bottom: 2rem;
}

ul {
  list-style: none;
  padding-left: 2rem;
}

h2 {
  margin-bottom: 2rem;
}

.testimonial {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  border-radius: 15px;
  -webkit-box-shadow: -22px 20px 22px -21px rgba(143, 143, 143, 0.4);
          box-shadow: -22px 20px 22px -21px rgba(143, 143, 143, 0.4);
  margin-bottom: 2rem;
  padding: 1rem;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.testimonial:hover {
  -webkit-box-shadow: -22px 20px 22px -21px rgba(92, 92, 92, 0.4);
          box-shadow: -22px 20px 22px -21px rgba(92, 92, 92, 0.4);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.testimonial h3 {
  margin-bottom: 1rem;
}

.testimonial img {
  height: 10rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 4px solid #2186a5;
  margin: auto 0;
}

.testimonial video {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 5px solid #2186a5;
}

.testimonial .content {
  padding-left: 2rem;
  max-width: 80%;
}

.buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 1rem;
}

.buttons button {
  width: 4rem;
  height: 2rem;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.buttons button:disabled {
  cursor: default !important;
}

.button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.button-container p {
  color: grey;
  font-size: 0.8rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 850px) {
  .about article img {
    height: 15rem;
    aspect-ratio: auto;
  }
  .about article p {
    font-size: 0.8rem;
  }
  .testimonial h3 {
    font-size: 1rem;
  }
  .testimonial P {
    font-size: 0.8rem;
  }
  .testimonial img {
    height: 8rem;
  }
  .testimonial video {
    height: 8rem;
    width: 8rem;
  }
}

@media screen and (max-width: 720px) {
  .about article {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0;
  }
  .about article img {
    width: 100%;
    -webkit-box-ordinal-group: 101;
        -ms-flex-order: 100;
            order: 100;
  }
}

@media screen and (max-width: 600px) {
  .testimonial h3 {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
  .testimonial P {
    font-size: 0.6rem;
  }
  .testimonial img {
    height: 6rem;
  }
  .testimonial video {
    height: 6rem;
    width: 6rem;
  }
  ul {
    padding-left: 0;
  }
  nav {
    padding-left: 0;
  }
  nav h1 {
    margin: 0 auto;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 450px) {
  .testimonial h3 {
    font-size: 0.5rem;
  }
  .testimonial p {
    font-size: 0.5rem;
  }
  nav h1 {
    font-size: 1.2rem;
  }
  section h2 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
